@import "../../constants.scss";

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  position: fixed;
  top: 0;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;

  &.show {
    animation: fadeIn ease 0.2s forwards;
  }

  &.hide {
    animation: fadeOut ease 0.2s forwards;
  }
}
