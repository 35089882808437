@import "../../../../constants.scss";


.sample-images-wrapper {
 display: flex;
 flex-direction: column;
 align-items: center;
 overflow: hidden;
 width: 100vw;

 .image-row {
  display: flex;
  column-gap: 0.938rem;
  transition: transform 0.3s ease-out;

  &.top {
   align-items: flex-end;
   margin-bottom: 0.313rem;
  }

  &.bottom {
   align-items: flex-start;
  }
 }
}

@media only screen and (max-width: $mobile-breakpoint) {
 .sample-images-wrapper {
  .image-row {
   column-gap: 0.469rem;

   &.top {
    align-items: flex-end;
    margin-bottom: unset;
   }
  }
 }
}