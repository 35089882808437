@import "../../constants.scss";

.home-wrapper {
  .cover-image {
    position: relative;
    background-image: url("../../assets/images/cover.jpg");
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(0.94);
      animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
      translate: -50%;
      font-size: calc(20px + 2vmin);
      letter-spacing: 20px;

      span {
        opacity: 0;
        filter: blur(4px);
      }

      span:nth-child(1) {
        animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(2) {
        animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(3) {
        animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .home-wrapper {
    .cover-image {
      background-image: url("../../assets/images/cover_small.jpg");
      background-position: right;
      .title {
        letter-spacing: 7px;
        width: 100%;
      }
    }
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
