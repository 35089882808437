@import "../../constants.scss";

.contact-wrapper {
  position: relative;
  display: flex;
  max-width: unset;
  background-image: url("../../assets/images/contact/img_1.jpg");
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: unset;

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .contact-wrapper {
    flex-direction: column;
    background-position: left;
    background-image: url("../../assets/images/contact/img_1_small.jpg");
  }
}
