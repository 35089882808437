@import "../../constants.scss";

.image-fullscreen-wrapper {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: $black;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .close-btn {
    line-height: 36px;
    margin: 20px 20px 10px auto;
    font-size: $large;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .image-and-text-wrapper {
    display: flex;
    justify-content: center;

    .fullscreen-image {
      width: 100%;
      max-width: 40vw;
    }

    .image-caption {
      font-size: $small;
      margin-right: auto;

      .image-title {
        font-family: elmessiri-bold;
      }

      .image-location {
        font-size: $x-small;
      }
    }

    .image-text {
      margin: 0 20px;
      text-align: justify;
      max-width: $max-text-column-width;
      white-space: pre-wrap;
    }
  }

  figure {
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .image-fullscreen-wrapper {
    .image-and-text-wrapper {
      flex-direction: column;

      .fullscreen-image {
        width: 100%;
        max-width: unset;
      }

      .image-caption {
        margin-right: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .image-text {
        margin: 20px;
        max-width: 100%;
      }
    }

    .back-btn {
      border: none;
      background: none;
      color: $white;
      margin-bottom: 10px;
      font-size: $medium;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}