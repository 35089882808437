$large: 2.25rem;
$medium: 1rem;
$small: 0.875rem;
$x-small: 0.75rem;

$black: black;
$white: white;

$mobile-breakpoint: 768px;
$large-desktop-breakpoint: 1600px;

$max-text-column-width: 31.25rem;
