@import "./constants.scss";

@font-face {
  font-family: elmessiri;
  src: url("./assets/fonts/ElMessiri-Regular.ttf");
}

@font-face {
  font-family: elmessiri-bold;
  src: url("./assets/fonts/ElMessiri-Bold.ttf");
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: elmessiri;
  background-color: $black;
  color: $white;
}

.italic-text {
  font-style: italic;
}

.bold-text {
  font-family: elmessiri-bold;
}

a {
  color: $white;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}

button {
  font-family: elmessiri-bold;
}

@media only screen and (max-width: $mobile-breakpoint) {
  section {
    flex-direction: column;
    padding: 0 15px;
    h2 {
      text-align: center;
    }
  }
}

@media only screen and (min-width: $large-desktop-breakpoint) {
  html {
    font-size: 20px;
  }
}

/* Disable hover for touch devices */
.touch-device .element:hover {
  background-color: initial; /* Reset or remove the hover styles */
}

* {
  -webkit-tap-highlight-color: transparent;
}
