@import "../../constants.scss";

.links-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    font-size: $small;
    margin: 5px 10px;
    color: white;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
}
