@import "../../constants.scss";

.image-preview-wrapper {
  cursor: pointer;

  .preview-image {
    border-radius: 10px;

    &:hover {
      opacity: 0.8;
    }
  }

  figure {
    margin: 0;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .image-preview-wrapper {
    .preview-image {
      max-width: 150px;
    }
  }
}