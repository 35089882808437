@import "../../constants.scss";

.content-wrapper {
  h2 {
    letter-spacing: 3px;
  }

  p {
    text-align: justify;
  }

  .text-box {
    margin: auto;
    max-width: $max-text-column-width;
    padding: 50px 80px;
  }

  .parallax-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .everything-is-possible {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #00000061;
      padding: 10px;
      width: max-content;
      text-align: center;
    }
  }

  .img_1 {
    position: relative;
    background-image: url("../../assets/images/parallax/img_1.jpg");
    height: 60vh;
    width: 100vw;
  }

  .img_2 {
    background-image: url("../../assets/images/parallax/img_2.jpg");
    height: 60vh;
    width: 100vw;
    background-attachment: unset;
    background-position-y: -100px;
  }

  .author-image {
    margin-top: 30px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .content-wrapper {
    h2 {
      letter-spacing: unset;
    }

    .text-box {
      padding: 20px;
    }

    .img_1 {
      height: 70vh;
      background-image: url("../../assets/images/parallax/img_1_small.jpg");
    }

    .img_2 {
      height: 70vh;
      background-image: url("../../assets/images/parallax/img_2_small.jpg");
      background-position-y: unset
    }

    .parallax-image {
      background-attachment: scroll;
    }
  }
}